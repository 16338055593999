import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import paper_bg from "../../assets/img/paper_bg.png";
import { v4 as uuidv4 } from 'uuid';
import Header from '../../layout/v3/v3NavigationBar';
import SearchBox from '../v3/searchBox';

function GreetingCard() {

  const navigate = useNavigate();
  const [greetingData, setGreeting] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [activeCategory, setActiveCategory] = useState(null);
  const path = window.location.pathname;
  const [selectedItems, setSelectedItems] = useState([]);
  const [showWrapper, setShowWrapper] = useState(false);
  const [cartIds, setCartIds] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchParams] = useSearchParams();
  const EventId = searchParams.get("event_id");
  const Search = searchParams.get("keyword");
  const Orient = searchParams.get("orient");
  const Cardtype = searchParams.get("cardtype");
  const searchSet = searchParams.get("search");
  const Categorysearch = searchParams.get("category");
  const Price = searchParams.get("price");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const location = useLocation();
  const category = localStorage.getItem("category");
  const subCategory = localStorage.getItem("subcategory");

console.log("searchSet", searchSet)
// if(searchSet){
//   setIsSearchVisible(true)
// }
  useEffect(() => {
    if (Search || searchSet) {
      setIsSearchVisible(true);
      setSearchKeyword(Search);
    } else {
      
      setIsSearchVisible(false);
    }
  }, [Search]);

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };
  const handlecategoryData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}category/list?front_category=true`);
      if (res.data.status === "success") {
        const data = res.data.result.map((item) => {
          return {
            title: item.category,
            subcategory: item.subcategory.map((subCategory) => ({
              id: subCategory._id,
              subcategory: subCategory.subcategory
            }))
          };
        });
        setCategoryData(data);
      } else {
        toast.error("Failed to fetch category data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching category data");
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === "/greeting_card" || pathname === "/") {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");

      fetchGreetingData();
      handlecategoryData();
    }
    if (!userId) {
      if (!randomId) {
        const uniqueId = uuidv4();
        window.localStorage.setItem("v3_random_id", uniqueId);
      }
    }

  }, []);

  const handlePriceFilterChange = (e) => {
    const selectedpriceRange = e.target.value;
    console.log("selectedpriceRange");
    console.log(selectedpriceRange);

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('price', selectedpriceRange);
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?${currentParams.toString()}`
    );
    fetchGreetingData(undefined, undefined, undefined, selectedpriceRange);

  };
  const handleColorClick = (color) => {
    setSelectedColor(color);
    console.log("Selected Color:", color);

  };

  const toggleCategory = (index, title) => {

    setActiveCategory((prev) => (prev === index ? null : index));
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('category', title);
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?${currentParams.toString()}`
    );
    fetchGreetingData(undefined, undefined, title)

  };

  const handleCardtypeFilterChange = (e) => {
    const selectedCardtype = e.target.value;

    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('cardtype', selectedCardtype);
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?${currentParams.toString()}`
    );
    fetchGreetingData(undefined, selectedCardtype);
  };
  const handleorientFilterChange = (e) => {
    const selectedorient = e.target.value;
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('orient', selectedorient);
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?${currentParams.toString()}`
    );
    fetchGreetingData(selectedorient, undefined);
  };

  const fetchGreetingData = async (orientation = Orient, colleCardtype = Cardtype, Searchcategory = Categorysearch, Pricerange = Price) => {
    // console.log("orientation",orientation)
    // console.log("colleCardtype",colleCardtype)
    // console.log("Searchcategory",Searchcategory)

    try {
      const params = new URLSearchParams();

      if (Search) {
        params.set('search_text', Search);
      }
      if (orientation) {
        params.set('orientation', orientation);
      }
      if (colleCardtype) {
        params.set('cart_type', colleCardtype);
      }
      if (Searchcategory) {
        params.set('search_category', Searchcategory);
      }
      if (Pricerange) {
        params.set('sorting', Pricerange);
      }

      const url = `${process.env.REACT_APP_APIURL}greeting/list?${params.toString()}`;
      // console.log("url----");
      // console.log(url);

      const res = await axios.get(url);
      if (res.data.status === "success") {
        setGreeting(res.data.result);
      } else {
        toast.error("Failed to fetch greeting data");
      }
    } catch (err) {
      console.error("Error fetching greeting data:", err);
      toast.error("Failed to fetch greeting data");
    }
  };
  const handleAddClick = (id) => {
    if (!selectedItems.includes(id)) {
      setSelectedItems((prev) => [...prev, id]);
      setShowWrapper(true);
    }
  };

  const handleRemoveClick = (id) => {
    setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    if (selectedItems.length === 1) {
      setShowWrapper(false);
    }
  };

  const handleProceed = async () => {
    try {
      let newCartIds = [];

      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?event_id=${EventId}`);
      if (res.data.status === "success") {
        const fetchedCartIds = res.data.result.cart.map((item) => item._id);
        newCartIds = [...newCartIds, ...fetchedCartIds];
        setCartIds((prev) => [...prev, ...fetchedCartIds]);
      } else {
        toast.error("Failed to fetch gift data");
        return;
      }


      for (const item of selectedItems) {
        const addedId = await AddToCart(item);
        if (addedId && !newCartIds.includes(addedId)) {
          newCartIds.push(addedId);
        }
      }
      setCartIds(newCartIds);

      const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
      const payload = {
        event_id: EventId,
        cart_ids: newCartIds.toString(),
      };
      try {
        const response = await axios.post(apiUrl, payload);
        if (response.data.status === "success") {
          console.log("Event updated successfully!");
          window.location.href = `/cart`;
        } else {
        }
      } catch (error) {
        console.error("Update event error:", error);
      }

    } catch (error) {
      toast.error("An error occurred while processing your request");
    }
  };

  const AddToCart = async (id) => {
    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      event_id: EventId ? EventId : '',
      cart_type: "direct",
      order_data_count: "1",
      greeting_card: id,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}event/add/cart`, payload);
      // console.log(`${process.env.REACT_APP_APIURL}event/add/cart`);

      if (response.data.status === "success") {
        return response.data.result._id;
      } else {
        toast.error("Failed to add item to the cart");
        return null;
      }
    } catch (error) {
      toast.error("Failed to add item to the cart");
      return null;
    }
  };
  const handleclearUrl = () => {
    const currentUrl = `${window.location.origin}${location.pathname}`;
    window.location.href = currentUrl;
  };

  const handleCancel = () => {
    setSelectedItems([]);
    setShowWrapper(false);

  }

  return (
    <main>

      <Header onSearchToggle={handleSearchToggle} /> {/* Pass function to Header */}
      <SearchBox isSearchVisible={isSearchVisible} /> {/* Pass state to SearchBox */}

      <section className="card-top pt-130 my-5">
        <div className="container my-4">
          {/* <div className="card invitation-card1 bg_lightgreen">
            <div className="card-body">
              <h1 className="card-title">
                Cards for &nbsp;
                {category ? category : "Birthday"}

              </h1>
              <h4>
                {subCategory ? subCategory : "Funny"}

              </h4>
              <p className="card-text f_sz16 mt-3">Join us to celebrate a special day filled with fun and joy.</p>
              <img src={paper_bg} />
            </div>
          </div> */}
        </div>
      </section>
      <section className="tp-shop-area">
        <div className="container">
          <div className="col-md-12">
            <div className="content w-100  mt-50 mb-50">
              <div className="tp-shop-widget-content handleclearUrl">
                <button className="btn btn-sm btn-gray" style={{ backgroundColor: "#808080", color: "#fff", border: "none", display: "none" }} onClick={handleclearUrl}>Show All</button>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="tp-shop-sidebar border1 p-3 mb-15">
                    {/* filter */}
                    <div className="tp-shop-widget mt-15 mb-35">
                      <h3 className="tp-shop-widget-title">Card Type</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="cardtype1" name="cardtype" value="folded" onChange={handleCardtypeFilterChange} />
                              <label htmlFor="cardtype1">Folded</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="cardtype2" name="cardtype" value="flat" onChange={handleCardtypeFilterChange} />
                              <label htmlFor="cardtype2">Flat</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* filter */}
                    <div className="tp-shop-widget mb-35">
                      <h3 className="tp-shop-widget-title">Price</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange2" name="payment" value="under_150" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange2">Under Rs.150</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange3" name="payment" value="150_to_200" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange3">Rs.150 to Rs.200</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange4" name="payment" value="200_to_250" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange4">Rs.200 to Rs.250</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange5" name="payment" value="250_to_300" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange5">Rs.250 to Rs.300</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* categories */}
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Categories</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-categories">
                          <ul>
                            {categoryData.map((category, index) => (
                              <li key={index}>
                                <a
                                  className={`category crsr_pntr ${activeCategory === index ? "active" : ""
                                    }`}
                                  onClick={() => toggleCategory(index, category.title)}
                                >
                                  {category.title}
                                  <span>
                                    <i
                                      className={`fa-solid ${activeCategory === index
                                        ? "fa-caret-up"
                                        : "fa-caret-down"
                                        }`}
                                    />
                                  </span>
                                </a>
                                <ul
                                  className="ms-3 mt-2 subcategory"
                                  style={{
                                    display: activeCategory === index ? "block" : "none",
                                  }}
                                >
                                  {category.subcategory.map((sub, subIndex) => (
                                    <li key={subIndex}>
                                      <a
                                        className={sub.active ? "active" : ""} onClick={() => toggleCategory(index, sub.subcategory)}
                                      >
                                        {sub.subcategory}
                                        {/* <span>{sub.count}</span> */}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* Colors 
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Color</h3>

                      <div className="tp-product-details-variation-list">
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#f8b655" ? "active" : ""}`}
                          onClick={() => handleColorClick("#f8b655")}
                        >
                          <span data-bg-color="#f8b655" style={{ backgroundColor: "#f8b655" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#cbcbcb" ? "active" : ""}`}
                          onClick={() => handleColorClick("#cbcbcb")}
                        >
                          <span data-bg-color="#cbcbcb" style={{ backgroundColor: "#cbcbcb" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#494e52" ? "active" : ""}`}
                          onClick={() => handleColorClick("#494e52")}
                        >
                          <span data-bg-color="#494e52" style={{ backgroundColor: "#494e52" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#b4505a" ? "active" : ""}`}
                          onClick={() => handleColorClick("#b4505a")}
                        >
                          <span data-bg-color="#b4505a" style={{ backgroundColor: "#b4505a" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#a850b4" ? "active" : ""}`}
                          onClick={() => handleColorClick("#a850b4")}
                        >
                          <span data-bg-color="#a850b4" style={{ backgroundColor: "#a850b4" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#3441d5" ? "active" : ""}`}
                          onClick={() => handleColorClick("#3441d5")}
                        >
                          <span data-bg-color="#3441d5" style={{ backgroundColor: "#3441d5" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#34d586" ? "active" : ""}`}
                          onClick={() => handleColorClick("#34d586")}
                        >
                          <span data-bg-color="#34d586" style={{ backgroundColor: "#34d586" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#9e0624" ? "active" : ""}`}
                          onClick={() => handleColorClick("#9e0624")}
                        >
                          <span data-bg-color="#9e0624" style={{ backgroundColor: "#9e0624" }} />
                        </button>
                      </div>
                    </div>*/}
                    {/* orientation */}
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Orientation</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient1" name="orient" value="portrait" onChange={handleorientFilterChange} />
                              <label htmlFor="orient1">Portrait</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient2" name="orient" value="landscape" onChange={handleorientFilterChange} />
                              <label htmlFor="orient2">Landscape</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient3" name="orient" value="square" onChange={handleorientFilterChange} />
                              <label htmlFor="orient3">Square</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-8">
                  <div className="row">
                    {greetingData.map((data) => (
                      <div className="col-xl-4 col-md-6 col-sm-6">
                        <div className="tp-product-item-2 mb-50">
                          <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                          {!EventId ? (
                                <>
                                  <a href={`/add_event?id=${data._id}&type=${"greeting_card"}`}>
                                    <img
                                      src={data.url}
                                      alt={data.name}
                                    />
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a>
                                    <img
                                      src={data.url}
                                      alt={data.name}
                                    />
                                  </a>
                                </>
                              )}

                            <div className="tp-product-action">
                              <a href="#"><span className="action_icon"><i className="fa-regular fa-heart f_sz18" /></span></a>
                            </div>
                          </div>
                          <div className="tp-product-content-2 pt-15 position-relative">
                            <h3 className="tp-product-title-2">
                              {data.name}
                            </h3>
                            <div className="tp-product-price-wrapper-2 d-flex align-items-center">
                              <span className="tp-product-price-2 new-price">Rs. {data.price}.00</span>
                              <div className="ms-auto">
                                {!EventId ? (
                                  <a
                                    href={`/add_event?id=${data._id}&type=greeting_card`}
                                    className="btn btn-sm btn-outline-dgreen"
                                  >
                                    Place Order
                                  </a>
                                ) : (
                                  <div>
                                    {selectedItems.includes(data._id) ? (
                                      <a
                                        className="btn btn-sm btn-dgreen removebtn1"
                                        onClick={() => handleRemoveClick(data._id)}
                                      >
                                        Remove
                                      </a>
                                    ) : (
                                      <a
                                        className="btn btn-sm btn-outline-dgreen addbtn addbtn1"
                                        onClick={() => handleAddClick(data._id)}
                                      >
                                        Add
                                      </a>
                                    )}
                                  </div>
                                )}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showWrapper && (
        <div className="deleoptns_wrapper">
          <div className="deleoptns_content position-relative">
            <div className="me-3">
              <a onClick={() => handleCancel()}>
                <i className="fa-solid fa-xmark f_20 deleoptns_close" />
              </a>
            </div>
            <div className="ms-2">
              <span className="me-2">{selectedItems.length}</span> selected
            </div>
            <div className="ms-4 me-1">
              <a onClick={() => handleProceed()} className="btn btn-sm btn-yellow">
                Proceed
              </a>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default GreetingCard;
