import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import front_image from "../assets/img/fornt_page/gif_banner.gif";
function Home() {
  const [greetingData, setGreeting] = useState([]);
  const [giftData, setGift] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  
  const handleSearch = async (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      setIsSearching(true);
      try {
        // Fetch gifts with search
        const giftRes = await axios.get(
          `${process.env.REACT_APP_APIURL}gift/list?search=${searchValue}`
        );
        
        // Fetch greetings with search
        const greetingRes = await axios.get(
          `${process.env.REACT_APP_APIURL}greeting/list?search=${searchValue}`
        );

        // Combine results
        const combinedResults = [
          ...(giftRes.data.status === "success" ? giftRes.data.result : []),
          ...(greetingRes.data.status === "success" ? greetingRes.data.result : [])
        ];

        setSearchResults(combinedResults);
      } catch (err) {
        console.error("Error during search:", err);
        setSearchResults([]);
      }
    } else {
      setIsSearching(false);
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.body.classList.add("bgyellow");
    fetchGift();
    fetchGreetingData();
  }, []);
  useEffect(() => {
    handleFilterClick("all");
  }, [greetingData, giftData]);
  const fetchGift = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}gift/list`);
      if (res.data.status === "success") {
        setGift(res.data.result);
        setDisplayedProducts(getRandomProducts(res.data.result, "gift"));
      } else {
        toast.error("Failed to fetch gift data");
      }
    } catch (err) {
      console.error("Error fetching gift data:", err);
      toast.error("Failed to fetch gift data");
    }
  };

  const fetchGreetingData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}greeting/list`
      );
      if (res.data.status === "success") {
        setGreeting(res.data.result);
        setDisplayedProducts(getRandomProducts(res.data.result, "greeting"));
      } else {
        toast.error("Failed to fetch greeting data");
      }
    } catch (err) {
      console.error("Error fetching greeting data:", err);
      toast.error("Failed to fetch greeting data");
    }
  };
  // Function to get the current day index (e.g., today -> 12th, tomorrow -> 13th)
  const getIndexForDay = (dayOffset = 0) => {
    const today = new Date();
    const currentDay = today.getDate(); // Current day of the month (1-31)
    const index = currentDay - 1 + dayOffset; // Zero-indexed
    return index;
  };

  const getGiftData = (dayOffset = 0) => {
    const index = getIndexForDay(dayOffset);
    const giftIndex =
      giftData.length > index && giftData[index]
        ? index
        : Math.floor(Math.random() * giftData.length);
    const gift = giftData.length > 0 ? giftData[giftIndex] : {};
    return gift;
  };

  const getGreetingData = (dayOffset = 0) => {
    const index = getIndexForDay(dayOffset);
    const greetingIndex =
      greetingData.length > index && greetingData[index]
        ? index
        : Math.floor(Math.random() * greetingData.length);
    const greeting = greetingData.length > 0 ? greetingData[greetingIndex] : {};

    console.log("Selected greeting:", greeting);
    return greeting;
  };

  // Helper function to get random items from a list
  const getRandomProducts = (data, itemCount) => {
    const randomItems = [];
    const max = data.length;

    while (randomItems.length < itemCount && randomItems.length < max) {
      const randomIndex = Math.floor(Math.random() * max);
      if (!randomItems.includes(data[randomIndex])) {
        randomItems.push(data[randomIndex]);
      }
    }

    return randomItems;
  };

  const handleFilterClick = (filterType) => {
    if (filterType === "all") {
      // When the "All products" button is clicked, show 2 random gifts and 2 random greeting cards
      setDisplayedProducts([
        ...getRandomProducts(giftData, 2), // Get 2 random gifts
        ...getRandomProducts(greetingData, 2), // Get 2 random greeting cards
      ]);
    } else if (filterType === "gifts") {
      // When the "Gifts" button is clicked, show 4 random gifts
      setDisplayedProducts(getRandomProducts(giftData, 4));
    } else if (filterType === "greeting-cards") {
      // When the "Greeting Cards" button is clicked, show 4 random greeting cards
      setDisplayedProducts(getRandomProducts(greetingData, 4));
    }
    setActiveFilter(filterType); // Update the active filter state
  };

  return (
    <>

    
      <div>
        <main>
          <div className="container d-flex align-items-center mt-5 pt-100 pb-30">
            <div className="row w-100">
              {/* Left Column */}
              <div className="col-12 col-md-6 col-sm-12 d-flex align-items-center justify-content-center pt-70 ">
                <div className="text-left">
                  <h1 className="display-5 f_textgreen pb-2 fw-bold">
                    Events made easy, <br />
                    gifts made <span className="f_lightgreen3">special!</span>
                  </h1>

                  <p className="lead pt-2">
                    At EventsEasy, we make gifting effortless and memorable.
                    <br />
                    From heartfelt greeting cards to thoughtful presents, find
                    the perfect way to celebrate every occasion—all in one
                    place.
                  </p>
                </div>
              </div>
              {/* Right Column */}
              <div className="col-12 col-md-6 col-sm-12 d-flex align-items-center justify-content-center pt-70 ">
                <img
                  src={front_image}
                  alt="Animated GIF"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </main>
        <section className="bg_sandal">
          <div className="container py-5">
            <h1 className="cardsection_head text-center mb-4 f_textgreen ">
              Whether it's birthdays, anniversaries, or just a little surprise,{" "}
              <strong className="f_textgray">
                we help you make every moment special.
              </strong>
            </h1>



            <div className="row gy-4 justify-content-center">


              {/* Card 1 */}
              {/* <div className="col-12 col-sm-6 col-md-4 mx-4">
                <div className="card shadow-sm">
                  <div className="card-body position-relative">
                    <div className="background-1">
                      <img
                        src={giftData.length > 0 ? getGiftData().url : ""}
                        alt="Gift"
                        className="img-overlay"
                      />
                    </div>
                    <div className="card-footer d-flex justify-content-between align-items-center bg-white px-3 py-2">
                      <div>
                        <h6 className="mb-0 f_28">
                          {giftData.length > 0 ? getGiftData().name : ""}
                        </h6>
                        <p className="mb-0 text-danger f_20">
                          ₹{giftData.length > 0 ? getGiftData().price : ""}
                        </p>
                      </div>
                      <div className="icon-wrapper">
                        <i className="fa-solid fa-heart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}


              <div className="col-12 col-sm-6 col-md-4 mx-md-4">
                <div className="card shadow-sm">
                  <div className="card-wrapper">
                    <div className="card-image-container">
                      <div className="background-1 zoom-image">

                        <img src={greetingData.length > 0 ? getGreetingData().url : ""} alt="Greeting Card" className="card-overlay-image zoom-overlay" />
                      </div>

                    </div>
                    <div className="card-footer-custom">
                      <div className="card-info">
                        <h6 className="mb-0 f-28">{greetingData.length > 0
                          ? getGreetingData().name
                          : ""}</h6>
                        <p className="mb-0 text-danger f-20 fw-bold"> {greetingData.length > 0
                          ? getGreetingData().price
                          : ""}</p>
                      </div>
                      <div className="icon-wrapper">
                        <i className="fa-solid fa-heart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2 */}


              <div className="col-12 col-sm-6 col-md-4 mx-md-4">
                <div className="card shadow-sm">
                  <div className="card-wrapper">
                    <div className="card-image-container">
                      <div className="background-2 zoom-image">
                        <img src={giftData.length > 0 ? getGiftData().url : ""} alt="Gift" className="card-overlay-image zoom-overlay" />
                      </div>
                    </div>
                    <div className="card-footer-custom">
                      <div className="card-info">
                        <h6 className="mb-0 f-28">{giftData.length > 0 ? getGiftData().name : ""}</h6>
                        <p className="mb-0 text-danger f-20 fw-bold">₹
                          ₹{giftData.length > 0 ? getGiftData().price : ""}</p>
                      </div>
                      <div className="icon-wrapper">
                        <i className="fa-solid fa-heart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 

              <div className="col-12 col-sm-6 col-md-4 mx-4 ">
                <div className="card shadow-sm">
                  <div className="card-body position-relative">
                    <div className="background-2 position-relative">
                      <img
                        src={greetingData.length > 0 ? getGreetingData().url : ""}
                        alt="Greeting Card"
                        className="img-overlay"
                      />
                    </div>
                    <div className="card-footer d-flex justify-content-between align-items-center bg-white px-3 py-2">
                      <div>
                        <h6 className="mb-0 f_28">
                          {greetingData.length > 0
                            ? getGreetingData().name
                            : ""}
                        </h6>
                        <p className="mb-0 text-danger f_20">
                          ₹
                          {greetingData.length > 0
                            ? getGreetingData().price
                            : ""}
                        </p>
                      </div>
                      <div className="icon-wrapper">
                        <i className="fa-solid fa-heart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section>
          <div className="container py-5">
            <h1 className="products_heading text-center mb-4 fw-bold">
              "Make every occasion special with India's #1 online gift store!"
            </h1>
            <p className="text-center" style={{ fontSize: "20px" }}>
              "Handpicked collection of 100,000 perfect gifts".
            </p>
            {/* Filter Buttons */}
            <div className="text-center filter-buttons">
              <button
                type="button"
                className={`btn btn-outline-success rounded ${activeFilter === "all" ? "active" : ""
                  }`}
                data-filter="all"
                onClick={() => handleFilterClick("all")}
              >
                All products
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary rounded ${activeFilter === "greeting-cards" ? "active" : ""
                  }`}
                data-filter="greeting-cards"
                onClick={() => handleFilterClick("greeting-cards")}
              >
                Greeting Cards
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary rounded ${activeFilter === "gifts" ? "active" : ""
                  }`}
                data-filter="gifts"
                onClick={() => handleFilterClick("gifts")}
              >
                Gifts
              </button>
            </div>
            {/* Images */}
            <div className="row mt-4">
              {displayedProducts.map((product, index) => (
                <div className="col-12 col-sm-6 col-md-3 image-card greeting-cards active">
                  <div className="product-card">
                    <div className="image-wrapper">
                      <div className="image-container">
                        <img src={product.url || "default-image.png"} alt="greeting-cards 1" />
                      </div>
                      {/* <button className="fav-icon">
                        <i className="fa-solid fa-heart" />
                      </button> */}
                    </div>
                  </div>
                </div>
              ))}

              {/* {displayedProducts.map((product, index) => (
                <div
                  key={index}
                  className={`col-md-3 image-card ${product.type} active my-3`}
                >
                  <div className="image-container">
                    <img
                      src={product.url || "default-image.png"} // Fallback image
                      className="img-fluid"
                      alt={product.name}
                    />
                    <span className="fav-icon">
                      <i className="fa-solid fa-heart" />
                    </span>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center bg-white px-3 py-2">
                    <div>
                      <h6 className="mb-0 f_28">{product.name}</h6>
                      <p className="mb-0 text-danger f_20">₹{product.price}</p>
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </section>
      </div>
      <div className="features-section py-5 bg_sandal">
        <div className="container text-center">
          {/* Section Title */}
          <h1 className="fw-bold mb-4 pb-2 ">
            India’s Leading Online Gift Store – Send Gifts, Cards &amp; More!
          </h1>
          <p className="text-muted">
            EventsEasy is your go-to destination for thoughtful gifting, making
            every celebration extra special. From birthdays and anniversaries to
            festivals like Rakhi, Diwali, and Christmas, we offer a wide range
            of handpicked gifts for every occasion. Choose from flower bouquets,
            personalized keepsakes, chocolates, gift hampers, and more. With
            express delivery to major cities, we ensure your gifts arrive on
            time, every time!
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;
