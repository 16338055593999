

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const shadowimg = `${process.env.PUBLIC_URL}../assets/img/shadowimg.png`;
const emptycheckout = `${process.env.PUBLIC_URL}../assets/img/emptycheckout.png`;
// const shadowimg = `${process.env.PUBLIC_URL}../../`;
function Checkout() {
  const [amount, setAmount] = useState(500);
  const [rayPayloading, setrayPayloading] = useState(false);
  const [showUserdataloading, setshowUserdataloading] = useState(false);
  const [searchParams] = useSearchParams();
  const [eventlist, setEventList] = useState(null);
  const [configinfo, setConfigInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("v3_user_id");
  const v3_first_name = localStorage.getItem("v3_first_name");
  const v3_email_address = localStorage.getItem("v3_email_address");
  const v3_phone_number = localStorage.getItem("v3_phone_number");
  const EventId = searchParams.get("id");
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [showAnimation, setShowAnimation] = useState(false);
  const [greetingCards, setGreetingCards] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [cartId, setCartId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [display, setDisplay] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    if (!formData.mobileNumber.trim()) {
      tempErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      tempErrors.mobileNumber = "Enter a valid 10-digit mobile number";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const updatePayload = {
          user_id: userId,
          first_name: v3_first_name,
          phone_number: formData.mobileNumber,
          password: ""
        };
        console.log(updatePayload);
        const updateApiUrl = "https://api.eventseasy.in:2020/api/newuser/profileupdate";

        console.log("Update payload:", updatePayload);

        const response = await fetch(updateApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayload),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === "success") {
          localStorage.setItem("v3_phone_number", responseData.result.phone_number);

          toast.success("Profile updated");
          setshowUserdataloading(false);
        } else {
          setshowUserdataloading(false);
          console.error(responseData.message || "Failed to update profile.");
        }
      } catch (error) {
        setshowUserdataloading(false);
        console.error("Error updating profile:", error);

      }
    }
  };


  const calculatedTotals = useMemo(() => {
    if (!eventlist || !eventlist.cart) {
      return {
        totalSum: 0,
        shippingCost: 60,
        taxAmount: 0,
        totalAmount: 60
      };
    }
    const totalSum = eventlist.cart.reduce((sum, item) => {
      const hasGiftOrCard = item.gift || item.greeting_card;
      return hasGiftOrCard ? sum + (item.total_price || 0) : sum;
    }, 0);

    const shippingCost = 60;
    const tax = 5;
    const taxAmount = (totalSum * (tax / 100)).toFixed(2);
    const totalAmount = (shippingCost + totalSum + parseFloat(taxAmount)).toFixed(2);

    return {
      totalSum,
      shippingCost,
      taxAmount,
      totalAmount
    };
  }, [eventlist]);

  useEffect(() => {
    if (eventlist && eventlist.cart) {
      const fetchItems = async () => {
        const greetingPromises = eventlist.cart
          .filter(item => item.greeting_card)
          .map(item =>
            axios
              .get(`${process.env.REACT_APP_APIURL}greeting/list?greeting_id=${item.greeting_card}`)
              .then(res => ({
                ...res.data.result,
                cartId: item._id,
                count: item.order_data_count
              }))
          );
        const giftPromises = eventlist.cart
          .filter(item => item.gift)
          .map(item =>
            axios
              .get(`${process.env.REACT_APP_APIURL}gift/list?gift_id=${item.gift}`)
              .then(res => ({
                ...res.data.result,
                cartId: item._id,
                count: item.order_data_count
              }))
          );
        const greetings = await Promise.all(greetingPromises);
        const giftsData = await Promise.all(giftPromises);

        setGreetingCards(greetings.flat());
        setGifts(giftsData.flat());
      };
      fetchItems();
    }
  }, [eventlist]);

  useEffect(() => {
    const pathname = location.pathname.startsWith("/");
    if (pathname) {
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    // if (!EventId) {
    //   window.location.href = `/cart`;
    // }
    setshowUserdataloading(false);
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    fetchconfiginfo();
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;


    const url = !EventId
      ? `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      : `${process.env.REACT_APP_APIURL}event/list?event_id=${EventId}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setEventList(res.data.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        console.log("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handlQuantityClick = async (giftId, count, itemType, incrementType, cartid) => {
    const newQuantity = incrementType === "increment" ? count + 1 : count - 1;
    console.log("newQuantity", newQuantity);

    if (newQuantity < 1) {
      toast.error("Minimum quantity is 1");
      return;
    }
    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      order_data_count: newQuantity,
      cart_id: cartid,
      ...(itemType === "greeting_card"
        ? { greeting_card: giftId }
        : { gift: giftId })
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/add/cart`,
        payload
      );
      if (response.data.status === "success") {
        await fetchUpdatedchekoutData();
      } else {
        console.log("Failed to update checkout");
      }
    } catch (error) {
      console.log("Failed to update checkout data");
    }
  };

  const fetchUpdatedchekoutData = async () => {
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;


    const url = !EventId
      ? `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      : `${process.env.REACT_APP_APIURL}event/list?checkoutlist=true&event_id=${EventId}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setEventList(res.data.result);
        } else {
          console.log("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        console.log("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchconfiginfo = () => {
    const url = `${process.env.REACT_APP_APIURL}config/info`
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setConfigInfo(res.data.result);
        } else {
          console.log("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        console.log("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (rayPayloading) {
    return (
      <div className="flex flex-col items-center justify-center p-4 space-y-2">
        <div className="w-8 h-8 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
        <p className="text-sm text-gray-600">Processing payment...</p>
      </div>
    );
  }
  if (!eventlist) {
    return <div>No events available for checkout.</div>;
  }

  const PaymentHandler = async () => {
    if (!v3_first_name || !v3_email_address || !v3_phone_number) {
      // Reset loading state since we're showing popup
      setrayPayloading(false);
      setshowUserdataloading(true);
      return;
    }

    try {
      setrayPayloading(true);
      setAmount(amount);

      if (!window.Razorpay) {
        throw new Error("Razorpay SDK not loaded");
      }
      console.log("----------totalAmount----------")
      console.log(totalAmount)

      if (userId) {
        const payload = {
          user_id: userId,
          eventIds: EventId,
          amount: totalAmount * 100,
        };

        const Orderdata = await axios.post(`${process.env.REACT_APP_APIURL}payment/orders`, payload);
        const orderPZY = Orderdata.data.data;
        setrayPayloading(false);

        console.log(orderPZY)

        const options = {
          key: 'rzp_test_Gasx2Z3WAqCylO',
          amount: totalAmount * 100,
          currency: 'INR',
          name: 'EventsEasy',
          description: 'Product description',
          image: shadowimg,
          order_id: orderPZY.order_id,
          handler: function (response) {
            console.log('Payment successful:', response);
            setShowAnimation(true);
            // razorpay_order_id: "order_PorjXOoR9xyfHU"
            // razorpay_payment_id: "pay_PorkJ7luTkMZUC"
            // razorpay_signatur: "e335c49c20e69c925e451f5427ea63bb736516e3954f7335790b09da22219b84"

            // const updatepayload = {
            //   user_id: userId,
            //   event_id: EventId,
            //   purchasestatus: "shipped",
            // };
            // try {
            //   const response = axios.post(
            //     `${process.env.REACT_APP_APIURL}event/update`,
            //     updatepayload
            //   );
            //   if (response.data.status === "success") {
            //     console.log("Item added to Shipping Process successfully");
            setTimeout(() => {
              window.location.href = `/confirmation?id=${EventId}`;
            }, 3000);
            //   } else {
            //     console.log("Failed to add item to Shipping Process");
            //   }
            // } catch (error) {
            //   console.error("Error adding item to Shipping Process:", error);
            // }
          },
          prefill: {
            name: v3_first_name,
            email: v3_email_address,
            contact: v3_phone_number,
          },
          notes: {
            address: fullAddress,
          },
          theme: {
            color: '#011f26',
          },
          modal: {
            ondismiss: function () {
              setrayPayloading(false);
            }
          }
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        window.localStorage.setItem("v3_signup_eventId", EventId);
        window.location.href = `/login?id=${EventId}`;
      }
    } catch (error) {
      console.error('Error during payment setup:', error);
      console.log('Error creating order');
      setrayPayloading(false);
    }
  };
  const fullAddress = [
    eventlist?.address,
    eventlist?.city,
    eventlist?.state,
    eventlist?.zip_code,
  ].filter(Boolean).join(", ");

  const handleDeleteEvent = async (id) => {
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;
    const payload = {
      [key]: value,
      event_id: id
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/delete`,
        payload
      );
      if (response.data.status === "success") {
        window.location.href = `/cart`;
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      console.log("Failed to add to cart");
    }
  };

  const calculateTotalAmount = (cart, greetingCards, gifts) => {
    let total = 0;
    if (!cart) return 0;
    cart.forEach((item) => {
      if (item.greeting_card) {
        const card = greetingCards.find((gc) => gc._id === item.greeting_card);
        if (card) {
          total += (parseInt(card.price || 0) * item.order_data_count);
        }
      }

      if (item.gift) {
        const gift = gifts.find((g) => g._id === item.gift);
        if (gift) {
          total += (parseInt(gift.price || 0) * item.order_data_count);
        }
      }
    });

    return total;
  };
  const eventsum = calculateTotalAmount(eventlist.cart, greetingCards, gifts);
  const shippingCost = 60;
  const tax = 5;
  const taxAmount = (eventsum * (tax / 100)).toFixed(2);
  const totalAmount = (shippingCost + eventsum + parseFloat(taxAmount)).toFixed(2);


  const handlesingleproductDelete = async (cartId, eventId) => {
    try {
      const updatedCart = eventlist.cart.filter(item => item._id !== cartId);
      const updatedEventList = {
        ...eventlist,
        cart: updatedCart
      };
      setEventList(updatedEventList);
      const newCartIds = updatedCart.map(item => item._id).toString() || '';
      const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
      const payload = {
        event_id: eventId,
        cart_ids: newCartIds,
      };


      const response = await axios.post(apiUrl, payload);


      if (response.data.status === "success") {
        console.log("Item removed successfully");
        setDisplay(updatedCart.length > 0);
      }
    } catch (error) {
      console.error("Error removing item:", error);
      console.log("Failed to remove item");
    }
  };
  const renderGreetingCards = () =>
    greetingCards.length > 0 ? (
      greetingCards.map((card, index) => (
        <div key={index} className="greetingcardgift_wrapper">
          <div className="greetingcardgift_item">
            <div className="greetingcardgift_img_sm position-relative">
              <img src={card.url} alt={card.name} />
              {greetingCards.length + gifts.length == 1 ?
                <div className="delete_icon_sm">
                  <i data-bs-toggle="modal" data-bs-target="#modal_event_singleproduct" onClick={() => setDeleteId(EventId)}
                    className="fa-regular fa-trash-can f_sz18 f_red" />
                </div>
                :

                <div className="delete_icon_sm">
                  <i data-bs-toggle="modal" data-bs-target="#modal_removegreetromcart" onClick={() => { setCartId(card.cartId); setEventId(eventlist._id); }} className="fa-regular fa-trash-can f_sz18 f_red" />
                </div>
              }

            </div>
            <div className="greetingcardgift_price">
              <span>{card.name}</span>
              <span className="f_sz14 w-100"> Rs. {card.price}.00</span>
            </div>
            <div className="flexdiv justify-content-center align-items-center mt-5">
              <h6 className="me-3">Quantity</h6>
              <div className="tp-product-quantity mt-10 mb-10">
                <span className="tp-cart-minus">
                  <i onClick={() => handlQuantityClick(card._id, card.count, "greeting_card", "decrement", card.cartId)} className="fa-solid fa-minus crsr_pntr" />
                </span>
                <input className="tp-cart-input" type="text" value={card.count} readOnly />
                <span className="tp-cart-plus">
                  <i onClick={() => handlQuantityClick(card._id, card.count, "greeting_card", "increment", card.cartId)} className="fa-solid fa-plus crsr_pntr" />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      // <div>No greeting cards added.</div>
      ""
    );
  const renderGifts = () =>
    gifts.length > 0 ? (
      gifts.map((gift, index) => (
        <div key={index} className="greetingcardgift_wrapper">
          <div className="greetingcardgift_item">
            <div className="greetingcardgift_img_sm position-relative">
              <img src={gift.url} alt={gift.name} />
              {greetingCards.length + gifts.length == 1 ?
                <div className="delete_icon_sm">
                  <i data-bs-toggle="modal" data-bs-target="#modal_event_singleproduct" onClick={() => setDeleteId(EventId)}
                    className="fa-regular fa-trash-can f_sz18 f_red" />
                </div>
                :
                <div className="delete_icon_sm">
                  <i data-bs-toggle="modal" data-bs-target="#modal_removeGiftfromcart" onClick={() => { setCartId(gift.cartId); setEventId(eventlist._id); }} className="fa-regular fa-trash-can f_sz18 f_red" />
                </div>
              }
            </div>
            <div className="greetingcardgift_price">
              <span>{gift.name}</span>
              <span className="f_sz14 w-100"> Rs. {gift.price}.00</span>
            </div>
            <div className="flexdiv justify-content-center align-items-center mt-5">
              <h6 className="me-3">Quantity</h6>
              <div className="tp-product-quantity mt-10 mb-10">
                <span className="tp-cart-minus">
                  <i onClick={() => handlQuantityClick(gift._id, gift.count, "gift", "decrement", gift.cartId)} className="fa-solid fa-minus crsr_pntr" />
                </span>
                <input className="tp-cart-input" type="text" value={gift.count} readOnly />
                <span className="tp-cart-plus">
                  <i onClick={() => handlQuantityClick(gift._id, gift.count, "gift", "increment", gift.cartId)} className="fa-solid fa-plus crsr_pntr" />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      ""
    );

  return (
    <>
      <main>
        <section className="tp-login-area pt-150 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="position-relative">
                  <div className="row mb-30 align-items-center">
                    {(EventId > 0) ? (
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <h3 className="tp-login-title">Checkout</h3>
                      </div>
                    ) : ('')}
                  </div>
                  {(EventId) ? (
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive">
                          <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ minWidth: 50 }} className="text-center">S.No</th>
                                <th style={{ minWidth: 600 }} className="text-center">Details</th>
                                <th style={{ minWidth: 250 }} className="text-center">Quantity</th>
                                <th style={{ minWidth: 250 }} className="text-center">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-days f_darkyellow mr-10" /><span className="fw_pjs700 f_darkgreen f_sz16">{formatDate(eventlist.event_date)}</span></div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-user f_darkyellow mr-10" /><span className="fw_pjs700">{eventlist.event_name}</span></div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-check f_darkyellow mr-10" />{eventlist.event_type}</div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-phone f_darkyellow mr-10" />{eventlist.phone_number}</div>
                                  <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                    {fullAddress}
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="flexdiv">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className="fw_pjs700">Greeting Card</div>
                                      <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                        {eventlist.greeting_notes}
                                      </div>
                                      {renderGreetingCards()}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className="fw_pjs700">Gift</div>
                                      {renderGifts()}
                                    </div>
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="w-100 d-flex flex-row flex-wrap">
                                    <div><a href={`/greeting_card?event_id=${eventlist._id}`} className="btn btn-yellow btn-sm m-2">Add Another Greeting Card</a>
                                    </div>
                                    <div>
                                      <a href={`/gift?event_id=${eventlist._id}`} className="btn btn-yellow btn-sm m-2">Add Another Gift</a>
                                    </div>
                                    <div><a data-bs-toggle="modal" data-bs-target="#modal_removefromcart" onClick={() => setDeleteId(eventlist._id)} className="btn btn-danger btn-sm m-2">Delete</a></div>
                                  </div>
                                </td>
                                <td className="text-center f_sz16">{greetingCards.length + gifts.length}</td>
                                <td className="text-center fw_pjs700 f_sz16">
                                  Rs. {eventsum}.00
                                </td>
                              </tr>
                            </tbody><tfoot>
                              <tr>
                                <td colSpan={3} className="txtalgn_rgt fw_pjs700">
                                  <div className="f_sz18">Subtotal</div>
                                  <div className="f_sz18 mt-10">Shipping</div>
                                  <div className="f_sz18 mt-10">Tax</div>
                                  <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                </td>
                                <td className="text-center fw_pjs700">
                                  <div className="f_sz18">Rs. {eventsum}.00</div>
                                  <div className="f_sz18 mt-10">Rs. {shippingCost}.00</div>
                                  <div className="f_sz18 mt-10">Rs. {taxAmount}</div>
                                  <div className="f_sz22 mt-10">Rs. {totalAmount}</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div className="w-100 d-flex flex-row flex-wrap">
                        <span className="fw_pjs700">Note: </span>You cannot make changes to your order once order is placed and paid.
                      </div>
                      <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                        <div><a onClick={(e) => {
                          e.preventDefault();
                          PaymentHandler();
                        }} className="btn btn-dgreen btnpdng_md m-2">Pay</a></div>
                      </div>
                    </div>
                  ) : (
                    // <div className="row mb-30 align-items-center">
                    //   <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                    //     <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                    //       No checkouts in your list.
                    //     </div>
                    //   </div>
                    // </div>

                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive">
                          <table width="100%" cellPadding={0} cellSpacing={0} className="" style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td className="p-4" style={{ border: 'none' }}>
                                  <div className="row align-items-center">
                                    {/* Left side - Empty cart message */}
                                    <div className="col-6 col-md-4 text-center">
                                      <div className="p-4">
                                        <img
                                          src={emptycheckout}
                                          alt="Empty Cart"
                                          // className="img-fluid"
                                          style={{ maxWidth: '300px' }}
                                        />
                                      </div>

                                    </div>
                                    {/* Right side - Image */}
                                    <div className="col-12 col-md-4 text-center">
                                      <div className="p-4">
                                        <h2 className="fw_pjs700 f_sz22 mb-3">Your Checkout page is Empty</h2>
                                        <p className="f_sz16 mb-4">Looks like you haven't added any greeting cards or gifts yet.</p>
                                        <a href="/" className="btn btn-yellow">
                                          Start Shopping
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showAnimation && (
              <div className="animation-overlay">
                <div className="logo-container">
                  <img
                    src={shadowimg}
                    alt="Logo"
                    className="loadinglogo"
                  />
                </div>
                <div className="success-message">
                  <h2>Payment Successful!</h2>
                  <p>Thank you for your purchase</p>
                  <div className="redirect-text">Redirecting you shortly...</div>
                </div>
              </div>
            )}

            {showUserdataloading && (
              <div className="animation-overlay fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                <div className="profile-message bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md sm:max-w-lg lg:max-w-2xl text-center space-y-4">
                  <h2 className="text-xl font-bold text-green-800 sm:text-2xl">
                    Update Mobile Number
                  </h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        maxLength={10}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                      />
                      {errors.mobileNumber && (
                        <p className="text-red-600 text-sm">{errors.mobileNumber}</p>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-yellow-400 hover:bg-yellow-500 text-sm py-2 px-4 rounded-md transition-colors duration-200"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </section>

        <div className="modal fade" id="modal_removeGiftfromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  Are you sure you want to remove the gift?
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal_removegreetromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  Are you sure you want to remove the greeting card?
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal_removefromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  Are you sure you want to remove the event.
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a onClick={() => handleDeleteEvent(deleteId)} data-bs-dismiss="modal" className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal_event_singleproduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  There must be at least one item to create an event. Removing this item will delete the entire event. Are you sure you want to proceed?
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a data-bs-dismiss="modal" onClick={() => handleDeleteEvent(deleteId)} className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>


      </main>
      <ToastContainer />
    </>
  );
}


export default Checkout;







