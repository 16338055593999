import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const iconsgoogle = `${process.env.PUBLIC_URL}../assets/img/iconsgoogle.png`;
const iconsfacebook = `${process.env.PUBLIC_URL}../assets/img/iconsfacebook.png`;

function SignupV3() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [resendTimer, setResendTimer] = useState(30);
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const v3_signup_eventId = localStorage.getItem("v3_signup_eventId");

  const togglePasswordVisibility = (field) => {
    if (field === "password") setShowPassword(!showPassword);
    if (field === "confirmPassword") setShowconfirmPassword(!showconfirmPassword);

  };
  const getIPAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "127.0.0.1";
    }
  };

  const timerFunction = () => {
    let timer;

    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setCanResendOtp(true);
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/register") {
      document.body.classList.add("bgyellow");
    }

    const cleanupTimer = timerFunction();
    return cleanupTimer;
  }, [resendTimer]);
  const validateEmail = () => {
    let newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Name is required.";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    } else if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      newErrors.password = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.";
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required.";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const getLocationFromIP = async (ip) => {
    try {
      const response = await axios.get(`https://ipinfo.io/${ip}/json`);
      return response.data;
    } catch (error) {
      console.error("Error fetching location:", error);
      return {};
    }
  };

  const sendOTP = async (e) => {
    e.preventDefault();

    if (validateEmail()) {
      localStorage.removeItem("verify_email_address");

      window.localStorage.setItem("verify_email_address", email);
      window.localStorage.setItem("verify_name", name);
      setLoading(true);

      const ip = await getIPAddress();
      const location = await getLocationFromIP(ip);

      try {
        const response = await axios.post(`${process.env.REACT_APP_APIURL}newuser/signup`, {
          first_name: name,
          email_address: email,
          password: password,
          ip_address: ip,
          usercity: location.city || "Unknown",
          userstate: location.region || "Unknown",
          usercountry: location.country || "Unknown",
          userzipcode: location.postal || "Unknown",
        });

        if (response.data.status === "success") {
          toast.success("OTP Sent to your email.");
          setOtpSent(true);
          setCanResendOtp(false);
          setResendTimer(30);
        } else {
          toast.error("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        console.error("OTP sending error:", error);
        toast.error("email address already exist.");
      } finally {
        setLoading(false);
      }
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();

    if (!otp.trim()) {
      setErrors(prev => ({ ...prev, otp: "OTP is required" }));
      return;
    }

    setOtpVerificationLoading(true);
    const verify_email = localStorage.getItem("verify_email_address");

    const apply_email_address = (!email) ? verify_email : (email)

    console.log(apply_email_address);
    console.log(otp);

    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}newuser/verify`, {
        email_address: apply_email_address,
        otp: otp
      });

      if (response.data.status === "success") {
        window.localStorage.setItem("v3_email_address", email);
        window.localStorage.setItem("v3_user_id", response.data.result.user_id);
        window.localStorage.setItem("v3_first_name", name);

        console.log("User registered successfully.");
        await axios.post(`${process.env.REACT_APP_APIURL}event/update/cart`, {
          user_id: response.data.result.user_id,
          random_string: randomId,
        });
        const redirectUrl = v3_signup_eventId ? `/checkout?id=${v3_signup_eventId}` : '/';
        window.location.href = redirectUrl;

      } else {
        toast.error(response.data.error?.message || "Registration failed");
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error("Please enter a valid OTP.");
    } finally {
      setOtpVerificationLoading(false);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    const verify_name = localStorage.getItem("verify_name");
    const verify_email = localStorage.getItem("verify_email_address");
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}newuser/resendotp`, {
        email_address: verify_email,
        first_name: verify_name,

      });
      if (response.data.status === "success") {
        toast.success("OTP sent to your email.");
        setCanResendOtp(false);
        setResendTimer(30);
      } else {
        toast.error(response.data.error?.message || "Signup failed");
      }
    } catch (error) {
      console.error("OTP sending error:", error);
      // toast.error("An error occurred while sending OTP");
    } finally {
      setLoading(false);
    }
  };

  // Social Authentication Handlers
  const handleGoogleSignUp = async () => {
    toast.error("Google sign-up failed");
    // try {
    //   // Redirect to Google OAuth endpoint
    //   window.location.href = `${process.env.REACT_APP_APIURL}auth/google`;
    // } catch (error) {
    //   console.error("Google Sign-up error:", error);
    //   toast.error("Google sign-up failed");
    // }
  };

  const handleFacebookSignUp = async () => {
    toast.error("Facebook sign-up failed");
    // try {
    //   // Redirect to Facebook OAuth endpoint
    //   window.location.href = `${process.env.REACT_APP_APIURL}auth/facebook`;
    // } catch (error) {
    //   console.error("Facebook Sign-up error:", error);
    //   toast.error("Facebook sign-up failed");
    // }
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">{!otpSent ? "Sign Up" : "Verify OTP"}</h3>
                  <p>
                    Already have an account?{" "}
                    <span>
                      <a href="/login" className="f_darkyellow">
                        Login
                      </a>
                    </span>
                  </p>
                </div>
                <div className="tp-login-option">
                  {!otpSent ? (
                    <>

                      {/* Social Sign Up Buttons */}
                      <div className="social-signup mb-3">
                        <button
                          className="btn btn-outline-danger w-100 mb-2 d-flex align-items-center justify-content-center"
                          onClick={handleGoogleSignUp}
                        >
                          <img
                            src={iconsgoogle}
                            alt="Google"
                            style={{ width: '20px', marginRight: '10px' }}
                          />
                          Sign up with Google
                        </button>
                        <button
                          className="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center"
                          onClick={handleFacebookSignUp}
                        >
                          <img
                            src={iconsfacebook}
                            alt="Facebook"
                            style={{ width: '20px', marginRight: '10px' }}
                          />
                          Sign up with Facebook
                        </button>

                        {/* Divider */}
                        <div className="d-flex align-items-center my-3">
                          <hr className="flex-grow-1" />
                          <span className="px-2 text-muted">OR</span>
                          <hr className="flex-grow-1" />
                        </div>
                      </div>

                      {/* Email Signup Form */}
                      <form onSubmit={sendOTP}>
                        <div className="tp-login-input-wrapper">
                          <div className="tp-login-input-box">
                            <div className="tp-login-input">
                              <input
                                id="name"
                                type="text"
                                placeholder="Full Name"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                  if (errors.name) {
                                    setErrors(prev => ({ ...prev, name: '' }));
                                  }
                                }}
                                style={{ borderColor: errors.name ? "red" : "" }}
                              />
                            </div>
                            {errors.name && (
                              <div className="error-text" style={{ color: "red" }}>
                                {errors.name}
                              </div>
                            )}
                          </div>
                          <div className="tp-login-input-box">
                            <div className="tp-login-input">
                              <input
                                id="email"
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  if (errors.email) {
                                    setErrors(prev => ({ ...prev, email: '' }));
                                  }
                                }}
                                style={{ borderColor: errors.email ? "red" : "" }}
                              />
                            </div>
                            {errors.email && (
                              <div className="error-text" style={{ color: "red" }}>
                                {errors.email}
                              </div>
                            )}
                          </div>

                          <div className="tp-login-input-box">
                            <div className="tp-login-input">
                              <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  if (errors.password) {
                                    setErrors(prev => ({ ...prev, password: '' }));
                                  }
                                }}
                                style={{ borderColor: errors.password ? "red" : "", }}
                              />
                              <div className="tp-login-input-eye cursor-pointer" id="password-show-toggle" onClick={() => togglePasswordVisibility('password')}>
                                {showPassword ? (
                                  <i className="fa-solid fa-eye-slash eyestyle" />
                                ) : (
                                  <i className="fa-solid fa-eye eyestyle" />
                                )}
                              </div>
                            </div>

                            {errors.password && (
                              <div className="error-text" style={{ color: "red" }}>
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="tp-login-input-box">
                            <div className="tp-login-input">
                              <input
                                id="confirmPassword"
                                type={showconfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value);
                                  if (errors.confirmPassword) {
                                    setErrors((prev) => ({ ...prev, confirmPassword: "" }));
                                  }
                                }}
                                style={{ borderColor: errors.confirmPassword ? "red" : "" }}
                              />
                            </div>
                            {errors.confirmPassword && (
                              <div className="error-text" style={{ color: "red" }}>
                                {errors.confirmPassword}
                              </div>
                            )}
                            <div className="tp-login-input-eye cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2" id="password-show-toggle" onClick={() => togglePasswordVisibility('confirmPassword')}>
                              {showconfirmPassword ? (
                                <i className="fa-solid fa-eye-slash eyestyle" />
                              ) : (
                                <i className="fa-solid fa-eye eyestyle" />
                              )}
                            </div>
                          </div>


                        </div>
                        <div className="tp-login-bottom">
                          <button
                            type="submit"
                            className="btn btn-yellow w-100"
                            disabled={loading}
                          >
                            {loading ? "Sending OTP..." : "Continue"}
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <form onSubmit={verifyOTP}>
                      <div className="tp-login-input-wrapper">
                        <div className="tp-login-input-box">
                          <div className="tp-login-input">
                            <input
                              id="otp"
                              type="text"
                              placeholder="Enter 4-Digit OTP"
                              maxLength={4}
                              value={otp}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 4) {
                                  setOtp(value);
                                  if (errors.otp) {
                                    setErrors(prev => ({ ...prev, otp: '' }));
                                  }
                                }
                              }}
                              style={{ borderColor: errors.otp ? "red" : "" }}
                            />
                          </div>
                          {errors.otp && (
                            <div className="error-text" style={{ color: "red" }}>
                              {errors.otp}
                            </div>
                          )}
                        </div>
                      </div>

                      <input
                        id="email"
                        type="hidden"
                        value={email}
                      />
                      <div className="tp-login-bottom">
                        <button
                          type="submit"
                          className="btn btn-yellow w-100"
                          disabled={otpVerificationLoading}
                        >
                          {otpVerificationLoading ? "Verifying..." : "Verify OTP"}
                        </button>
                        <div className="text-center mt-3">
                          {canResendOtp ? (
                            <a
                              href="#"
                              onClick={resendOtp}
                              className={`text-primary ${loading ? 'text-muted disabled-link' : ''}`}
                              style={{
                                color: loading ? 'gray' : '',
                                pointerEvents: loading ? 'none' : 'auto',
                                textDecoration: 'none',
                              }}
                              disabled={loading}
                            >
                              {loading ? 'Sending...' : 'Resend OTP'}
                            </a>
                          ) : (
                            <span className="text-muted">
                              Resend OTP in {resendTimer}s
                            </span>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SignupV3;