import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPasswordV3() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = location.pathname.startsWith("/");
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);

console.log("Reset password")

  // State for form inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

  // Extracting user_id from URL query parameters
  const user_id = new URLSearchParams(location.search).get("user_id");

  const handleResetPassword = (e) => {
    e.preventDefault();

    // Basic validation
    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Please enter both new password and confirm password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_APIURL}newuser/password/reset`, {
        user_id: user_id,
        password: password,
      })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Password updated successfully");
          // Redirect to login or appropriate page after success
          navigate("/login");
        } else {
          if (res.data.error && res.data.error.message) {
            toast.error(res.data.error.message);
          } else {
            toast.error("An error occurred during password reset");
          }
        }
      })
      .catch((err) => {
        console.error("Password reset error:", err.response?.data?.error?.message);
        toast.error(err.response?.data?.error?.message || "An unexpected error occurred.");
      });
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Reset Password</h3>
                </div>
                <form onSubmit={handleResetPassword}>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      {/* New Password Field */}
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div
                          className="tp-login-input-eye forgetpasswordeye"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <i className="fa-solid fa-eye-slash" />
                          ) : (
                            <i className="fa-solid fa-eye" />
                          )}
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">New Password</label>
                        </div>
                      </div>

                      {/* Confirm Password Field */}
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div
                          className="tp-login-input-eye forgetpasswordeye"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? (
                            <i className="fa-solid fa-eye-slash" />
                          ) : (
                            <i className="fa-solid fa-eye" />
                          )}
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_confirm_password">
                            Confirm New Password
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="tp-login-bottom">
                      <button type="submit" className="btn btn-yellow w-100">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ResetPasswordV3;
