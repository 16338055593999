import React, { useState, useEffect } from "react";
import ReactSearchBox from "react-search-box";
const NavigationBar = () => {
  const [email, setEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, [isLoggedIn]);

  const checkUserAuthentication = () => {
    const token = window.localStorage.getItem("user_id");
    return !!token;
  };

  useEffect(() => {
    const email_address = window.localStorage.getItem("email_address");
    setEmail(email_address);
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const path = window.location.pathname;

  const data = [
    {
      key: "john",
      value: "John Doe",
    },
    {
      key: "jane",
      value: "Jane Doe",
    },
    {
      key: "mary",
      value: "Mary Phillips",
    },
    {
      key: "robert",
      value: "Robert",
    },
    {
      key: "karius",
      value: "Karius",
    },
  ];

  const handleSearch = (event) => {
    event.preventDefault();
    const query = document.getElementById('searchInput').value;
    if (query) {
        const newUrl = new URL(window.location);
        newUrl.searchParams.set('search', query);
        window.history.pushState({}, '', newUrl);
    }
  };

  return (
    <>
     
      {path == "/" ? (
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="index.html">
              <img
                src="assets/img/logo.png"
                alt="Logo"
                className="desktop_logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars f_30" />
              </span>
              
            </button>
            {/* <ReactSearchBox
        placeholder="Placeholder"
        value="Doe"
        data={data}
        callback={(record) => console.log(record)}
      /> */}
       <div className="search_allproduct_section me-2">
                <div className="search_allproduct">
                  <form className="d-flex" onSubmit={handleSearch}>
                    {/* <div className="input-group">
                      <input type="text" className="form-control placeholder-xs" placeholder="Search" id="searchInput" />
                      <span className="input-group-text"><i className="fa-solid fa-magnifying-glass f_whhite" /></span>
                    </div> */}
                  </form>
                </div>
              </div>

            <div className="collapse navbar-collapse" id="navbarToggler">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/greeting_card">
                    Greeting Card
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/gift">
                    Gift
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/register">
                    Sign Up
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/login">
                    Signin
                  </a>
                </li>
                {/* <li className="nav-item dropdown navprofile">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="prof_img">
                      {email ? email.charAt(0).toUpperCase() : "A"}
                    </span>{" "}
                    {email || "Alexander Pierce"}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#" onClick={logout}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      ) : path !== "/" ? (
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container">
            <a className="navbar-brand" href="index.html">
              <img
                src="assets/img/logo.png"
                border={0}
                className="desktop_logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars f_30" />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarToggler">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* <li class="nav-item">
          <a class="nav-link" href="register.html">Products</a>
       </li>

       <li class="nav-item">
        <a class="nav-link" href="login.html">Gallery</a>
       </li>

       <li class="nav-item">
        <a class="nav-link" href="register.html">Contact Us</a>
       </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="login.html">
                    Log In
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="signup.html">
                    Sign Up
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="index.html">
              <img
                src="assets/img/logo.png"
                alt="Logo"
                className="desktop_logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars f_30" />
              </span>
            </button>
            {path === "/v2" && (
              <div className="collapse navbar-collapse" id="navbarToggler">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" href="/register">
                      Register
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/login">
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

export default NavigationBar;
