import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";

function AllProductLit() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchitem = searchParams.get("search");

  const [giftData, setGiftData] = useState([]);
  const [greetingData, setGreetingData] = useState([]);
  const [combinedProducts, setCombinedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add("bgyellow");
    fetchData();
    return () => {
      document.body.classList.remove("bgyellow");
    };
  }, []);

  useEffect(() => {
    filterProducts();
  }, [searchitem, giftData, greetingData]);

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchGift(), fetchGreetingData()]);
    setLoading(false);
  };

  const fetchGift = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}gift/list`);
      if (res.data.status === "success") {
        setGiftData(res.data.result.map(item => ({ ...item, type: 'gift' })));
      }
    } catch (err) {
      console.error("Error fetching gift data:", err);
    }
  };

  const fetchGreetingData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}greeting/list`);
      if (res.data.status === "success") {
        setGreetingData(res.data.result.map(item => ({ ...item, type: 'greeting' })));
      }
    } catch (err) {
      console.error("Error fetching greeting data:", err);
    }
  };

  const filterProducts = () => {
    let filtered = [];
    
    if (!searchitem) {
      filtered = [...giftData, ...greetingData];
    } else {
      const searchTerm = searchitem.toLowerCase();
      
      const filteredGifts = giftData.filter(gift => 
        gift.name?.toLowerCase().includes(searchTerm) ||
        gift.description?.toLowerCase().includes(searchTerm) ||
        gift.category_details?.category?.toLowerCase().includes(searchTerm)
      );

      const filteredGreetings = greetingData.filter(greeting => 
        greeting.name?.toLowerCase().includes(searchTerm) ||
        greeting.description?.toLowerCase().includes(searchTerm) ||
        greeting.category_details?.category?.toLowerCase().includes(searchTerm)
      );

      filtered = [...filteredGifts, ...filteredGreetings];
    }

    const shuffled = filtered.sort(() => Math.random() - 0.5);
    setCombinedProducts(shuffled);
  };

  const handleProductClick = (product) => {
console.log("-----------------------")
console.log(product)

    if (product.type === 'gift') {
      navigate(`/add_event?id=${product._id}&type=gift`);
    } else {
      navigate(`/add_event?id=${product._id}&type=greeting_card`);
    }
  };

  return (
    <main className="container py-4">
      {loading ? (
        <div className="text-center py-5">
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {searchitem && (
            <h2 className="mb-4">Search Results for: "{searchitem}"</h2>
          )}

          {!combinedProducts.length ? (
            <div className="text-center py-5">
              <h3>No products found matching your search.</h3>
              <p>Try using different keywords or browse our categories.</p>
            </div>
          ) : (
            <div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4" style={{ marginTop: '83px' }}>
              {combinedProducts.map(product => (
                <div key={`${product.type}-${product._id}`} className="col">
                  <div className="card h-100 shadow-sm hover-shadow transition-all">
                    <div className="position-relative">
                    <a href={`/add_event?id=${product._id}&type=${product.type === 'gift' ? 'gift' : 'greeting_card'}`}>

                      <img 
                        src={product.url || `${process.env.REACT_APP_APIURL}${product.image}`}
                        className="card-img-top" 
                        alt={product.name}
                        style={{ height: '250px', objectFit: 'cover' }}
                      />
                      </a>

                      <span className="position-absolute top-0 end-0 m-2 badge bg-warning text-dark">
                        {product.category_details?.category}
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <h5 className="card-title mb-0">{product.name}</h5>
                        {product.price && (
                          <span className="badge bg-success fs-6">₹{product.price}</span>
                        )}
                      </div>
                      
                      <p className="card-text text-muted small mb-2">
                        {product.category_details?.subcategory}
                      </p>
                      
                      <p className="card-text text-truncate">
                        {product.description}
                      </p>
                      
                      <div className="mt-2">
                        <span className="badge bg-light text-dark">
                          {product.type === 'gift' ? 'Gift' : 'Greeting Card'}
                        </span>
                      </div>
                    </div>
                    <div className="card-footer bg-transparent border-top-0 p-3">
                      <button 
                        className="btn btn-warning w-100"
                        onClick={() => handleProductClick(product)}
                      >
                        Place Order
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
          )}
        </>
      )}
    </main>
  );
}

// Add these styles to your CSS
const styles = `
.hover-shadow:hover {
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}
`;

export default AllProductLit;