import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function Footer() {
  const path = window.location.pathname;

  return (


    <footer className="footer">
      <div className="tp-footer-area tp-footer-style-2 tp-footer-style-primary tp-footer-style-6" data-bg-color="#ffffff">
        <div className="tp-footer-bottom">
          <div className="container-fluid">
            <div className="tp-footer-bottom-wrapper">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="tp-footer-copyright">
                    <div className="d-flex flex-wrap flex-row justify-content-center">
                      <div>
                        <Link  to="/privacypolicy">Privacy Policy</Link >  &nbsp;| &nbsp;
                        <Link  to="/terms_condition">Terms of Service</Link >  &nbsp;| &nbsp;
                        <Link  to="/contactus">Contact Us</Link >  &nbsp;| &nbsp;
                        <Link  to="/cancellation_refund">Cancellation Refund</Link >  &nbsp;| &nbsp;
                        <Link  to="/shippingpolicy">Shipping Policy</Link >
                      </div>
                      <div className="footer_img w-100 d-flex justify-content-center"><img src="assets/img/logo.png" border={0} /></div>
                      <div><p>Copyright © 2024</p></div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>



  );
}

export default Footer;
