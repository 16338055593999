import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddEvent() {
  const [event_name, setvalidateName] = useState("");
  const [phone_number, setvalidatenumber] = useState("");
  const [event_date, setvalidatedate] = useState("");
  const [event_type, setEventType] = useState(""); // work
  const [greeting_notes, setvalidatenotes] = useState("");
  const [address, setvalidateaddress] = useState("");
  const [city, setvalidatecity] = useState("");
  const [zipcode, setvalidatezipcode] = useState("");//
  const [selectedState, setSelectedState] = useState("");
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const EventId = searchParams.get("id");
  const productId = searchParams.get("id");
  const productType = searchParams.get("type");

  const [event, setEvent] = useState({
    event_name: '',
    phone_number: '',
    event_date: null,
    event_type: '',
    greeting_notes: '',
    address: '',
    city: '',
    zipcode: '',
    state: '',
  });
  const statesOfIndia = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
  ];
  const [greetingData, setGreetingData] = useState([]);
  const [giftData, setGiftData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [selectedGreetingIds, setSelectedGreetingIds] = useState([]);
  const [selectedGiftIds, setSelectedGiftIds] = useState([]);
  const [cartIds, setCartIds] = useState([]);
  const eventNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const eventTypeRef = useRef(null);
  const addressRef = useRef(null);
  const eventDateRef = useRef(null);
  const greetingNotesRef = useRef(null);
  const cityRef = useRef(null);
  const zipcodeRef = useRef(null);
  const stateRef = useRef(null);

  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState(1);
  const [imageURL, setImageURL] = useState();
  const [addToCartButton, setAddToCartButton] = useState(false);




  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/add_event") {
      document.body.classList.add("bgyellow");
    }
    fetchGreetingData();
  }, []);


  const validateEmail = () => {
    let newErrors = {};
    let firstErrorField = null;

    if (!event.event_name) {
      newErrors.event_name = "Recipient Name is required.";
      firstErrorField = eventNameRef;
    }
    if (!event.phone_number.trim()) {
      newErrors.phone_number = "Phone Number is required.";
      firstErrorField = phoneNumberRef;
    }
    if (event.phone_number.length > 10) {
      newErrors.phone_number = "Phone Number cannot exceed 10 digits.";
      firstErrorField = phoneNumberRef;
    }
    if (!event.event_date) {
      newErrors.event_date = "Event date is required.";
      firstErrorField = eventDateRef;
    }
    if (!event.event_type || event_type === "Occasion") {
      newErrors.event_type = "Event type is required.";
      firstErrorField = eventTypeRef;
    }
    if (!event.greeting_notes.trim()) {
      newErrors.greeting_notes = "Greeting Notes is required.";
      firstErrorField = greetingNotesRef;
    }
    if (!event.address.trim()) {
      newErrors.address = "Address is required.";
      firstErrorField = addressRef;
    }
    if (!event.city.trim()) {
      newErrors.city = "City is required.";
      firstErrorField = cityRef;
    }
    if (!event.zipcode.trim()) {
      newErrors.zipcode = "Zipcode is required.";
      firstErrorField = zipcodeRef;
    }
    if (!selectedState) {
      setError('Please select a state');
      newErrors.state = "State is required.";
      firstErrorField = stateRef;
    }

    setErrors(newErrors);
    if (firstErrorField) {
      firstErrorField.current.focus();
    }
    return Object.keys(newErrors).length === 0;
  };


  const fetchGreetingData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}${productType === "greeting_card" ? "greeting" : "gift"}/list?${productType === "greeting_card" ? "greeting_id" : "gift_id"}=${productId}`);
      if (res.data.status === "success") {
        setName(res.data.result.name);
        setPrice(res.data.result.price);
        setImageURL(res.data.result.url);

      } else {
        toast.error("Failed to fetch greeting data");
      }
    } catch (err) {
      console.error("Error fetching greeting data:", err);
      toast.error("Failed to fetch greeting data");
    }
  };

  const handleAddEvent = async (e, action) => {
    e.preventDefault();

    if (validateEmail()) {


      const cartId = await AddToCart();

      const apiUrl = `${process.env.REACT_APP_APIURL}event/create`;
      const eventDate = new Date(event.event_date);
      const formattedDate = eventDate.toLocaleDateString("en-GB").replace(/\//g, '-');
      const payload = {
        [userId ? 'user_id' : 'random_string']: userId || randomId,
        event_date: formattedDate,
        event_name: event.event_name,
        phone_number: event.phone_number,
        event_type: event.event_type,
        address: event.address,
        state: selectedState,
        city: event.city,
        zip_code: event.zipcode,
        greeting_card: productType === "greeting_card" ? productId : "",
        greeting_notes: event.greeting_notes,
        gift: productType === "gift" ? productId : "",
        cart_ids: cartId.toString(),
      };

      try {
        const response = await axios.post(apiUrl, payload);

        if (response.data.status === "success") {
          if (action === "checkout") {
            navigate(`/checkout?id=${response.data.result.event_id}`);
          } else if (action === "Cart") {
            setAddToCartButton(true);
          }

        } else {
          toast.error("Failed to create event");
        }
      } catch (error) {
        console.error("Create event error:", error);
        toast.error("Failed to create event");
      }
    }
  };
  const AddToCart = async () => {

    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      order_data_count: quantity,
      ...(productType === "greeting_card"
        ? { greeting_card: productId }
        : { gift: productId })
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/add/cart`,
        payload
      );

      if (response.data.status === "success") {

        // toast.success("Added to cart successfully!", { autoClose: 1000 });
        console.log("Added to cart successfully!")
        setCartIds(response.data.result._id);
        return response.data.result._id;
      } else {
        toast.error("Failed to add to cart");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart");
    }
  };

  const handleChange = (e) => {
    setSelectedState(e.target.value);
    if (e.target.value !== '') {
      setError('');
    }
  };

  const handleLocation = () => {
    navigate(`/cart`);
  }

  const handleQuantity = (type) => {
    console.log(type);
    setQuantity((prev) => {
      if (type === "increment") {
        return prev + 1;
      } else if (type === "decrement") {
        return prev > 1 ? prev - 1 : 1; // Prevent negative values
      }
      return prev;
    });
  };
  


  return (
    <main>
      <section class="card-top pt-130 mb-15">
        <div class="container my-4">
          <h2>Place Order</h2>
        </div>
      </section>
      <section className="tp-shop-area">
        <div className="container">
          <div className="col-md-12">
            <div className="content w-100  mt-0 mb-50">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="greetingcard_gift_wrapper mb-25" style={{ maxWidth: '70%', textAlign: 'center' }}>
                    <h5 className="mb-15 f_sz22 w-100 text-center">{name}</h5>
                    <img 
                        src={imageURL} 
                        alt="Greeting Card" 
                        style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }} 
                    />
                    <div className="flexdiv justify-content-center mt-25 f_sz22 fw_pjs600">Rs.{price}.00</div>
                    <div className="flexdiv justify-content-center mt-25">
                        <h6 className="w-100 text-center">Quantity</h6>
                        <div className="tp-product-quantity mt-10 mb-10">
                            <span className="tp-cart-minus">
                            {addToCartButton ?
                                <i className="fa-solid fa-minus crsr_pntr" /> :
                                <i onClick={() => { handleQuantity("decrement") }} className="fa-solid fa-minus crsr_pntr" />}
                            </span>
                            <input className="tp-cart-input" type="text" value={quantity} readOnly />
                            <span className="tp-cart-plus">
                            {addToCartButton ?
                                <i className="fa-solid fa-plus crsr_pntr" /> :
                                <i onClick={() => { handleQuantity("increment") }} className="fa-solid fa-plus crsr_pntr" />
                            }
                            </span>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="flexdiv">
                    <h6 className="f_sz20 w-100">About Event</h6>
                  </div>
                  <div className="row mt-15 mb-10">
                    <div className="col-12">
                      <div className="mb-10">
                        <input
                          ref={eventNameRef}
                          type="text"
                          placeholder="Enter the Recipient Name"
                          value={event.event_name}
                          disabled={addToCartButton ? true : false}
                          onChange={(e) => {
                            if (errors.event_name) {
                              setErrors((prev) => ({ ...prev, event_name: '' }));
                            }
                            setEvent((prev) => ({ ...prev, event_name: e.target.value }));
                          }}
                          className={`form-control ${errors.event_name ? 'is-invalid' : ''}`}
                          style={{ maxWidth: '100%' }}
                        />
                        {errors.event_name && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.event_name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-10">
                        <input
                          ref={phoneNumberRef}
                          type="text"
                          maxLength={10}
                          disabled={addToCartButton ? true : false}
                          placeholder="Enter the Recipient Mobile Number"
                          value={event.phone_number}
                          onChange={(e) => {
                            if (errors.phone_number) {
                              setErrors((prev) => ({ ...prev, phone_number: '' }));
                            }
                            const value = e.target.value.replace(/\D/g, '');
                            setEvent((prev) => ({ ...prev, phone_number: value }));
                          }}
                          className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                          style={{ maxWidth: '100%' }}
                        />
                        {errors.phone_number && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.phone_number}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-15">
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fa-solid fa-calendar-days" /></span>
                        <DatePicker
                          ref={eventDateRef}
                          selected={event.event_date}
                          onChange={(date) => {
                            if (errors.event_date) {
                              setErrors((prev) => ({ ...prev, event_date: '' }));
                            }
                            setEvent((prev) => ({ ...prev, event_date: date }));
                          }}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Choose Date"
                          minDate={new Date()}
                          className={`form-control ${errors.event_date ? 'is-invalid' : ''}`}
                          onKeyDown={(e) => e.preventDefault()}
                          disabled={addToCartButton ? true : false}
                          style={{ maxWidth: '100%' }}
                        />
                        {errors.event_date && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.event_date}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="mb-10">
                        <select
                          ref={eventTypeRef}
                          className={`form-select ${errors.event_type ? 'is-invalid' : ''}`}
                          value={event.event_type}
                          disabled={addToCartButton ? true : false}
                          onChange={(e) => {
                            if (errors.event_type) {
                              setErrors((prev) => ({ ...prev, event_type: '' }));
                            }
                            setEvent((prev) => ({ ...prev, event_type: e.target.value }));
                          }}
                          style={{ maxWidth: '100%' }}
                        >
                          <option>Occasion</option>
                          <option>Mother's Day</option>
                          <option>Birthday</option>
                          <option>Wedding</option>
                          <option>Anniversary</option>
                          <option>Easter</option>
                          <option>Holi</option>
                          <option>Christmas</option>
                          <option>Deepavali</option>
                          <option>New Year</option>
                          <option>Father's Day</option>
                          <option>Navarathri</option>
                          <option>Pongal</option>
                          <option>Onam</option>
                          <option>Janmashtami</option>
                          <option>Valentines Day</option>
                          <option>Ganesh Chaturthi</option>
                          <option>Baby Shower</option>
                          <option>Makar Sankranti</option>
                          <option>...</option>
                        </select>
                        {errors.event_type && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.event_type}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-15 w-100">
                        <input
                          ref={greetingNotesRef}
                          type="text"
                          disabled={addToCartButton ? true : false}
                          placeholder="Enter the Personal Note"
                          value={event.greeting_notes}
                          onChange={(e) => {
                            if (errors.greeting_notes) {
                              setErrors((prev) => ({ ...prev, greeting_notes: '' }));
                            }
                            setEvent((prev) => ({ ...prev, greeting_notes: e.target.value }))
                          }
                          }
                          className={`form-control ${errors.greeting_notes ? 'is-invalid' : ''}`}
                        />
                        {errors.event_date && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.event_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flexdiv mt-25">
                    <h6 className="f_sz20 w-100">Recipient Details</h6>
                  </div>
                  <div className="row mt-20">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <textarea
                        ref={addressRef}
                        placeholder="Enter the Recipient Address"
                        disabled={addToCartButton ? true : false}
                        value={event.address}
                        onChange={(e) => {
                          if (errors.address) {
                            setErrors((prev) => ({ ...prev, address: '' }));
                          }
                          setEvent((prev) => ({ ...prev, address: e.target.value }))
                        }

                        }
                        className={`form-control txthgt100 ${errors.address ? 'is-invalid' : ''}`}
                      />

                    </div>
                    {errors.address && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {errors.address}
                      </div>
                    )}
                  </div>
                  <div className="row mt-15 mb-10">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-10">
                        <input
                          ref={cityRef}
                          type="text"
                          disabled={addToCartButton ? true : false}
                          placeholder="City"
                          value={event.city}
                          onChange={(e) => {
                            if (errors.city) {
                              setErrors((prev) => ({ ...prev, city: '' }));
                            }
                            setEvent((prev) => ({ ...prev, city: e.target.value }))
                          }
                          }
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        />
                        {errors.city && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.city}
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="mb-10">
                        <input
                          ref={zipcodeRef}
                          type="text"
                          placeholder="Zipcode"
                          value={event.zipcode}
                          disabled={addToCartButton ? true : false}
                          onChange={(e) => {
                            if (errors.zipcode) {
                              setErrors((prev) => ({ ...prev, zipcode: '' }));
                            }
                            setEvent((prev) => ({ ...prev, zipcode: e.target.value.replace(/\D/g, '') }))
                          }
                          }
                          className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                        />
                        {errors.zipcode && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.zipcode}
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                  <div className="row mt-15 mb-10">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-10">
                        <select
                          ref={stateRef}
                          className={`form-select ${error ? 'is-invalid' : ''}`}
                          value={selectedState}
                          onChange={handleChange}
                          disabled={addToCartButton ? true : false}
                        >
                          <option value="">Select State</option>
                          {statesOfIndia.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                        {error && <div className="text-danger mt-1">{error}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-25">
                        {addToCartButton ? (
                          <><div className="w-100"><a onClick={() => handleLocation()} className="btn btn-success w-100 m-2">Go To Cart</a></div></>
                        ) : (
                          <><div className="w-100"><a onClick={(e) => handleAddEvent(e, "Cart")} className="btn btn-yellow w-100 m-2">Add To Cart</a></div></>
                        )}

                        {addToCartButton ? (
                          <><div className="w-100"><a className="btn btn-secondary w-100 m-2 disabled" disabled>Send Now</a></div></>
                        ) : (
                          <><div className="w-100"><a onClick={(e) => handleAddEvent(e, "checkout")} className="btn btn-dgreen w-100 m-2">Send Now</a></div></>
                        )}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <ToastContainer />
    </main>
  );
}

export default AddEvent;
