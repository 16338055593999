import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from './../signup';

function SigninV3() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const EventId = searchParams.get("id");

  useEffect(() => {
    const pathname = location.pathname.startsWith('/');
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);

  const togglePasswordVisibility = (field) => {
    if (field === "tp_password") setShowPassword(!showPassword);
  };

  const validateFields = () => {
    let newErrors = {};

    if (!email.trim()) {
      newErrors.email = "Email is required.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSignIn = async (e) => {
    e.preventDefault();

    if (validateFields()) {
      setLoading(true);

      try {
        const res = await axios.post(`${process.env.REACT_APP_APIURL}newuser/login`, {
          email_address: email,
          password,
        });

        if (res.data.status === "success") {
          const { _id, first_name, phone_number } = res.data.result;
          window.localStorage.setItem("v3_email_address", email);
          window.localStorage.setItem("v3_user_id", _id);
          window.localStorage.setItem("v3_first_name", first_name);
          window.localStorage.setItem("v3_phone_number", phone_number);

          console.log("User Logged in successfully.");
          await axios.post(`${process.env.REACT_APP_APIURL}event/update/cart`, {
            user_id: _id,
            random_string: randomId,
          });

          const redirectUrl = EventId ? `/checkout?id=${EventId}` : '/';
          window.location.href = redirectUrl;
        } else {
          const errorMessage = res.data.error?.message || " Invalid login credentials please try with a valid credentials.";
          toast.error(errorMessage);
        }
      } catch (err) {
        console.error("Login error:", err);
        const errorMessage = err.response?.data?.error?.message || "An unexpected error occurred.";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Login</h3>
                  <p>
                    Don't have an account?{" "}
                    <span>
                      <a href="/register" className="f_darkyellow">
                        Sign Up
                      </a>
                    </span>
                  </p>
                </div>
                <div className="tp-login-option">
                  <form onSubmit={handleSignIn}>
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (errors.email) {
                                setErrors(prev => ({ ...prev, email: '' }));
                              }
                            }}
                            style={{ borderColor: errors.email ? "red" : "", }}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                          {errors.email && (
                            <div className="error-text" style={{ color: "red" }}>
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="tp_password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if (errors.password) {
                                setErrors(prev => ({ ...prev, password: '' }));
                              }
                            }}
                            style={{ borderColor: errors.password ? "red" : "", }}
                          />
                        </div>
                        <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        >

                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                          {errors.password && (
                            <div className="error-text" style={{ color: "red" }}>
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="tp-login-input-eye cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2" id="password-show-toggle" onClick={() => togglePasswordVisibility('tp_password')}>
                          {showPassword ? (
                            <i className="fa-solid fa-eye-slash eyestyle" />
                          ) : (
                            <i className="fa-solid fa-eye eyestyle" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-suggestions d-sm-flex align-items-center justify-content-end mb-20">
                      {/* <div className="tp-login-forgot">
                        <a href="/forgot_password" className="f_darkyellow">
                          Forgot Password?
                        </a>
                      </div> */}
                    </div>
                    <div className="tp-login-bottom">
                      <button
                        type="submit"
                        className="btn btn-yellow w-100"
                        disabled={loading}
                      >
                        {loading ? "Signing in..." : "Sign In"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SigninV3;
