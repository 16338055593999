import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { initializeSocket, getSocket, disconnectSocket } from "../../components/v3/socket";


const NavigationBar3 = ({ }) => {

  // const NavigationBar3 = ({ onSearchToggle }) => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartCount, setCartCount] = useState();
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [categoryData, setCategoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams] = useSearchParams();
  const searchitem = searchParams.get("search");

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const currentUrl = new URL(window.location.href);
  //   currentUrl.searchParams.set('search', searchQuery);
  //   window.history.pushState({}, '', currentUrl);
  // };
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      window.location.href = `/allproduct/?search=${encodeURIComponent(searchQuery.trim())}`;
    }
  };

  useEffect(() => {
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, [isLoggedIn]);

  useEffect(() => {
    const socket = initializeSocket();

    if (socket) {
      socket.on('addtoevent', (data) => {
        if (data.user_id == userId || data.random_string == randomId) {
          fetchCartList();
        }
      });
    }

    return () => {
      disconnectSocket();
    };
  }, []);
  const handleSearchClick = () => {
    // Add search parameter to URL without page reload
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('search', 'true');
    window.history.pushState({}, '', currentUrl);

    // if (onSearchToggle) {
    //   onSearchToggle();
    // }
  };
  const checkUserAuthentication = () => {
    const token = window.localStorage.getItem("v3_user_id");
    return !!token;
  };

  useEffect(() => {
    const email_address = window.localStorage.getItem("v3_email_address");
    setEmail(email_address);
    const first_name = window.localStorage.getItem("v3_first_name");
    setFirstname(first_name);
    fetchCartList();
    handlecategoryData();
  }, []);


  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const fetchCartList = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?${userId ? `user_id=${userId}` : `random_string=${randomId}&checkoutlist=true`
        }`);
      if (res.data.status === "success") {
        setCartCount(res.data.result.length);
      }
    } catch (err) {
      console.error("Error fetching cart data:", err);
    }
  };

  const handlecategoryData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}category/list?front_category=true`);
      if (res.data.status === "success") {
        const data = res.data.result.map((item) => {
          return {
            title: item.category,
            subcategory: item.subcategory.map((subCategory) => ({
              id: subCategory._id,
              subcategory: subCategory.subcategory
            }))
          };
        });
        setCategoryData(data);
      }
    } catch (error) {
      // toast.error("An error occurred while fetching category data");
      console.log(error);
    }
  };

  const handleHeader = (category, subcategory) => {
    window.localStorage.setItem("category", category);
    window.localStorage.setItem("subcategory", subcategory);
  }

  const path = window.location.pathname;
  console.log("-------path----")
  console.log(path)
  return (
    <>
      <nav className="navbar navbar-expand-xl fixed-top topheader_nav">
        <div className="container-fluid justify-content-end">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="flexdiv">
                <div className="location_header me-2" style={{ display: 'none' }}>

                  <a data-bs-toggle="modal" data-bs-target="#modallocation" className="crsr_pntr"><i className="fa-solid fa-location-dot f_yellow me-1" /> Select Delivery Location <i className="fa-solid fa-angle-down ms-1" /></a>
                </div>
                {!isLoggedIn ? (
                  <>
                    <div className="topheader_link mx-2"><a href="/register">Sign Up</a></div>
                    <div className="topheader_link mx-2"><a href="/login">Login</a></div>
                  </>
                ) : (
                  ''
                )}
                <div className="topheader_link me-2">
                  <a href="/cart">
                    <span className="position-relative">
                      <i className="fa-solid fa-cart-shopping f_yellow f_sz18 posrel_top3"></i>{cartCount > 0 ? <span class="cart_count">{cartCount}</span> : ""}
                    </span>
                  </a>
                </div>
                {isLoggedIn ? (
                  <>
                    <div className="topheader_link nav-link dropdown navprofile">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="prof_img">{firstname ? firstname.charAt(0).toUpperCase() : " "}</span>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="brdr_btm pb-10 px-2">
                          <span className="f_darkyellow f_sz14">
                            {firstname}
                          </span>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/Profile">
                            My Profile
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/Checkout">
                            Checkout
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/Orders">
                            Your Orders
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" onClick={handleLogout} href="#">
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="navbar navbar-expand-xl fixed-top bottomheader_nav">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} border={0} className="desktop_logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars f_30" /></span>
          </button>

          {/* <div className="navsearch_product_icon" onClick={handleSearchClick}>
              <i className="fa-solid fa-magnifying-glass" />
            </div> */}
          {/* Add centered search box */}

          {(path === "/greeting_card" || path === "/gift" || path === "/" || path === " " || path === "/allproduct" || path === "/greeting_card/" || path === "/gift/" || path === "/allproduct/") && (

          <div className="navbar-search-container position-absolute start-50 translate-middle-x" style={{ top: '50%', transform: 'translateY(-50%)' }}>
            <form onSubmit={handleSearch} className="d-flex">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-input"
                  // placeholder="Search..."//searchitem
                  placeholder={searchitem ? searchitem : "Search..."}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    borderRadius: '20px 0 0 20px',
                    border: '1px solid #ddd',
                    padding: '8px 15px',
                    width: '300px'
                  }}
                />
                {/* <input
                  type="text"
                  className="form-control search-input"
                  placeholder={searchitem ? searchitem : "Search..."}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onBlur={() => {
                    if (searchQuery === "") {
                      setSearchQuery(""); // This keeps the placeholder visible
                    }
                  }}
                  style={{
                    borderRadius: "20px 0 0 20px",
                    border: "1px solid #ddd",
                    padding: "8px 15px",
                    width: "300px",
                  }}
                /> */}
                <button
                  className="btn btn-warning"
                  type="submit"
                  style={{
                    borderRadius: '0 20px 20px 0',
                    border: '1px solid #ffc107',
                    borderLeft: 'none'
                  }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </form>
          </div>
          )}

          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ms-auto mt-10 mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className={`nav-link ${path === "/greeting_card" ? "active" : ""}`} href="/greeting_card" role="button" aria-expanded="false">
                  Greeting Cards
                </a>
                {/* <a className={`nav-link dropdown-toggle ${path === "/greeting_card" ? "active" : ""}`} href="/greeting_card" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Greeting Cards
                </a> */}

                <div className="dropdown-menu dropdown-menu-lg megamenu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  {
                    categoryData.map((item, intex) => (
                      <ul>
                        <li><a className="dropdown-item">{item.title}</a></li>
                        {
                          item.subcategory.map((data, index) => (
                            <li><a className="dropdown-item" onClick={() => { handleHeader(item.title, data.subcategory) }} href={`/greeting_card?category=${data.subcategory}`}>{data.subcategory}</a></li>
                          ))
                        }
                      </ul>
                    ))
                  }


                </div>
              </li>
              <li className="nav-item dropdown">
                <a className={`nav-link ${path === "/gift" ? "active" : ""}`} href="/gift" role="button" aria-expanded="false">
                  Gifts
                </a>
                {/* <a className={`nav-link dropdown-toggle ${path === "/gift" ? "active" : ""}`} href="/gift" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Gifts
                </a> */}
                <div className="dropdown-menu dropdown-menu-lg megamenu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  {
                    categoryData.map((item, intex) => (
                      <ul>
                        <li><a className="dropdown-item">{item.title}</a></li>
                        {
                          item.subcategory.map((data, index) => (
                            <li><a className="dropdown-item" onClick={() => { handleHeader(item.title, data.subcategory) }} href={`/gift?category=${data.subcategory}`}>{data.subcategory}</a></li>
                          ))
                        }
                      </ul>
                    ))
                  }
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
};

export default NavigationBar3;
